import React from "react";
import { useNavigate } from "react-router-dom";

function Info() {
  const navigate = useNavigate();

  return (
    <>
      <div>
        This is a project for generating and sharing knitting color chart
      </div>
      <div>email: gongboolearn@gmail.com</div>
      <button
        onClick={() => {
          navigate("/make");
        }}
      >
        go back to make page
      </button>
    </>
  );
}

export default Info;
