import React from "react";

function LinkErrorPage() {
  return (
    <>
      error : something is wrong. <br /> Maybe the link is broken
    </>
  );
}

export default LinkErrorPage;
