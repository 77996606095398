import React from "react";

const RealDoubleCrochetShape = (props) => {
  return (
    <div style={{ width: props.size, height: props.size * 2 }}>
      <div
        style={{
          width: props.size,
          height: props.size * 2,
          backgroundSize: "cover",

          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          // backgroundColor: "rgba(255, 0, 0, 0.5)",
          backgroundImage: "url('/shape_dc.png')",
          backgroundColor: props.color,
          // border: "1px solid grey",
          boxSizing: "border-box",
        }}
      ></div>
    </div>
  );
};

export default RealDoubleCrochetShape;
