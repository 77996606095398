import logo from "./logo.svg";
import "./App.css";
import PixelMaker from "./PixelMaker";
import Router from "./Router";

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
