import React from "react";
import QRCode from "qrcode.react";

const QRCodeMaker = () => {
  const currentUrl = window.location.href;
  const MAX_LENGTH = 1852; // Version 40, Level L의 최대 용량 기준
  if (currentUrl.length > MAX_LENGTH) {
    return undefined;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignItems: "center",
      }}
    >
      <QRCode value={currentUrl} level="L" version="40" />
    </div>
  );
};

export default QRCodeMaker;
