import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";

import PixelMaker from "./PixelMaker";
import PixelDisplayer from "./PixelDisplayer";
import Info from "./Info";
import LinkErrorPage from "./LinkErrorPage";

function Router() {
  const router = createBrowserRouter([
    {
      path: "/make",
      element: <PixelMaker width={10} height={10} size={20}></PixelMaker>,
      errorElement: <LinkErrorPage />,
    },
    {
      path: "/",
      element: <PixelMaker width={10} height={10} size={20}></PixelMaker>,
      errorElement: <LinkErrorPage />,
    },
    {
      path: "/share",
      element: (
        <PixelDisplayer width={10} height={10} size={20}></PixelDisplayer>
      ),
      errorElement: <LinkErrorPage />,
    },
    {
      path: "/info",
      element: <Info />,
    },
    {
      path: "/lnkerr",
      element: <LinkErrorPage />,
    },
    {
      path: "/*",
      element: <div>not found</div>,
    },
  ]);
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
}
export default Router;
