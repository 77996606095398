import React from "react";

const Squareshape = (props) => {
  return (
    <div style={{ width: props.size, height: props.size }}>
      <div
        style={{
          width: props.size,
          height: props.size,
          backgroundColor: props.color,
          border: "1px solid grey",
          boxSizing: "border-box",
        }}
      ></div>
    </div>
  );
};

export default Squareshape;
