import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LinkErrorPage from "./LinkErrorPage";
import QRCodeMaker from "./QRCodeMaker";
import Modal from "react-modal";
import Footer from "./Footer";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "./messages";
import useWindowSize from "./useWindowSize";
import { base64ToBytes, pixelHeight, width, height, size } from "./utils";
import Pixel from "./Pixel";
import html2canvas from "html2canvas";

function PixelDisplayer(props) {
  const userLanguage = navigator.language.split("-")[0];
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const menuWidth =
    windowWidth * 0.4 < 200 ? windowWidth * 0.8 : windowWidth * 0.4;

  const locale =
    userLanguage === "ko" || userLanguage === "ja" ? userLanguage : "en";
  const [searchParams] = useSearchParams();
  const [isMouseDown, setIsMouseDown] = useState(false);

  const [colorPallette, setColorPallette] = useState(
    base64ToBytes(decodeURIComponent(searchParams.get("colors"))).split("|")
  );
  const fixedColorPallette = base64ToBytes(
    decodeURIComponent(searchParams.get("colors"))
  ).split("|");

  const [currentRow, setCurrentRow] = useState(null);

  const navigate = useNavigate();

  // 사용보류

  const [isToolBoxOpen, setIsToolBoxOpen] = useState(false);

  const handleToolbox = () => {
    setIsToolBoxOpen(!isToolBoxOpen);
  };

  const [isFifthRowShown, setIsFifthRowShown] = useState(false);

  // let colorPallette = base64ToBytes(
  //   decodeURIComponent(searchParams.get("colors"))
  // ).split("|");
  let pixelArrDataRaw = base64ToBytes(
    decodeURIComponent(searchParams.get("arr"))
  );
  let pixelArrDataRawRows = pixelArrDataRaw.split("%");
  let pixelArrData = Array.from(pixelArrDataRawRows, (row) =>
    row.split("|").map(Number)
  );
  let pixelShape = base64ToBytes(
    decodeURIComponent(searchParams.get("pxlshp"))
  );
  const title = base64ToBytes(decodeURIComponent(searchParams.get("title")));
  const name = base64ToBytes(decodeURIComponent(searchParams.get("name")));

  Modal.setAppElement("#root");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const handleColorChange = (idx, color) => {
    let tmp = [...colorPallette];
    tmp[idx] = color;
    setColorPallette(tmp);
  };
  const copyCurrentUrl = async () => {
    const currentUrl = window.location.href; // 현재 페이지 URL 가져오기

    try {
      await navigator.clipboard.writeText(currentUrl); // URL을 클립보드에 복사
      alert("URL copied to clipboard!");
    } catch (error) {
      alert("Failed to copy URL.");
    }
  };

  const ChartandNameRef = useRef(null);

  const handleCaptureChart = () => {
    if (ChartandNameRef.current) {
      html2canvas(ChartandNameRef.current).then((canvas) => {
        // 캔버스를 이미지로 변환
        const imgData = canvas.toDataURL("image/png");

        // 이미지를 다운로드
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "color-chart.png";
        link.click();
      });
    }
  };

  const ShareRef = useRef(null);

  const handleCaptureShare = () => {
    if (ShareRef.current) {
      html2canvas(ShareRef.current).then((canvas) => {
        // 캔버스를 이미지로 변환
        const imgData = canvas.toDataURL("image/png");

        // 이미지를 다운로드
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "color-chart-share.png";
        link.click();
      });
    }
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="centered">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "80%",
            maxWidth: 1000,
            gap: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "80%",
              maxWidth: 1000,
              gap: 10,
            }}
          >
            <div className="title-txt">{title}</div>
            <div className="common-txt">{name}</div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {colorPallette.map((color, idx) => (
                <div key={idx}>
                  <div>
                    <input
                      type="color"
                      value={colorPallette[idx]}
                      onChange={(e) => {
                        handleColorChange(idx, e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              ))}
            </div>
            <div className="centered">
              <div
                style={{
                  maxWidth: windowWidth * 0.8,
                  overflowX: "auto",
                  overflowY: "hidden",
                  paddingTop: 2,
                }}
              >
                {pixelArrData.map((i, index_i) => (
                  <div
                    key={index_i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: size * pixelHeight[pixelShape],
                      width: "fit-content",
                      boxSizing: "border-box",

                      outline:
                        currentRow === index_i
                          ? "3px outset red"
                          : "1px solid transparent",
                      // borderTop: "2px solid blue",
                      marginLeft: 2,
                      marginRight: 2,
                    }}
                    onPointerDown={() => {
                      if (currentRow === index_i) {
                        setCurrentRow(null);
                      } else {
                        setCurrentRow(index_i);
                      }
                    }}
                  >
                    <div
                      style={{
                        width: size,
                        minWidth: size,
                        height: "100%",
                        outline: "1px solid grey",
                        // boxSizing: "border-box",
                        backgroundColor:
                          currentRow === index_i ? "yellow" : "white",
                      }}
                      className="noDrag centered"
                    >
                      {pixelArrData.length - index_i}
                    </div>
                    {i.map((j, index_j) => (
                      <PixelWrapper
                        key={index_i + "_" + index_j}
                        isMouseDown={isMouseDown}
                        size={size}
                        currentColor={pixelArrData[index_i][index_j]}
                        pixelArrData={pixelArrData}
                        x={index_j}
                        y={index_i}
                        setIsMouseDown={setIsMouseDown}
                        colorPallette={colorPallette}
                        pixelShape={pixelShape}
                        isFifthRowShown={isFifthRowShown}
                      ></PixelWrapper>
                    ))}
                    <div
                      style={{
                        width: size,
                        minWidth: size,
                        height: "100%",
                        outline: "1px solid grey",
                        backgroundColor:
                          currentRow === index_i ? "yellow" : "white",

                        boxSizing: "border-box",
                      }}
                      className="noDrag centered"
                    >
                      {pixelArrData.length - index_i}
                    </div>
                  </div>
                ))}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      width: size,
                      minWidth: size,

                      height: size,
                      outline: "1px solid grey",
                      boxSizing: "border-box",
                      backgroundColor: "white",

                      marginLeft: 2,
                      marginBottom: 2,
                    }}
                  ></div>
                  {Array.from(
                    { length: pixelArrData[0].length },
                    (_, index) => index
                  ).map((_, idx) => (
                    <div
                      key={idx}
                      style={{
                        width: size,
                        minWidth: size,
                        height: size,
                        outline: "1px solid grey",
                        boxSizing: "border-box",
                        backgroundColor: "white",
                      }}
                      className="noDrag centered"
                    >
                      {pixelArrData[0].length - idx}
                    </div>
                  ))}
                  <div
                    style={{
                      width: size,
                      minWidth: size,
                      height: size,
                      outline: "1px solid grey",
                      boxSizing: "border-box",
                      backgroundColor: "white",
                      marginRight: 2,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={handleToolbox}
            style={{ border: "1px solid transparent" }}
            className="noDrag"
          >
            {isToolBoxOpen ? (
              <>
                <span
                  className="material-symbols-outlined"
                  aria-hidden="true"
                  translate="no"
                  style={{ color: "gray" }}
                >
                  expand_circle_up
                </span>
              </>
            ) : (
              <>
                <span
                  className="material-symbols-outlined"
                  aria-hidden="true"
                  translate="no"
                  style={{ color: "gray" }}
                >
                  expand_circle_down
                </span>
              </>
            )}
          </div>
          {isToolBoxOpen && (
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <div>
                <input
                  type="checkbox"
                  checked={isFifthRowShown}
                  onChange={() => {
                    setIsFifthRowShown(!isFifthRowShown);
                  }}
                ></input>
                <FormattedMessage id="isFifthRowShown" />
              </div>

              <button onClick={handleCaptureChart}>
                {" "}
                <FormattedMessage id="savechartasimage" />
              </button>
            </div>
          )}

          <div style={{ display: "flex", flexDirection: "row" }}>
            <button onClick={openModal}>
              {" "}
              <FormattedMessage id="share" />
            </button>
            <div
              style={{
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <FormattedMessage id="or" />
            </div>

            <button
              onClick={() => {
                navigate("/make");
              }}
            >
              <FormattedMessage id="makeNewOne" />
            </button>
          </div>

          <Footer />
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Share Modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                margin: "auto",
                padding: "0",
                width: "auto",
                height: "auto",
                maxWidth: "90%",
                maxHeight: "90%",
                border: "none",
                borderRadius: "8px",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                alignItems: "center",
              }}
              className="middle-txt"
            >
              <br /> <br />
              <div>
                <FormattedMessage id="shareTextQR" />
              </div>
              <div
                ref={ShareRef}
                style={{
                  padding: 20,
                }}
              >
                {title !== "" && name !== "" && (
                  <div>
                    {title} by {name}
                  </div>
                )}
                {title !== "" && name === "" && <div>{title}</div>}
                {title === "" && name !== "" && <div>by {name}</div>}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                    flexWrap: "wrap",
                  }}
                  className="center"
                >
                  <div>
                    {pixelArrData.map((i, index_i) => (
                      <div
                        key={index_i}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "fit-content",
                          boxSizing: "border-box",
                        }}
                      >
                        {i.map((j, index_j) => (
                          <div
                            key={index_i + "_" + index_j}
                            style={{
                              width: 150 / i.length,
                              height: 150 / pixelArrData.length,
                              backgroundColor:
                                fixedColorPallette[
                                  pixelArrData[index_i][index_j]
                                ],
                            }}
                          ></div>
                        ))}
                      </div>
                    ))}
                  </div>
                  {QRCodeMaker() ? (
                    <div>
                      <QRCodeMaker />
                      <div>knitshare.link</div>
                    </div>
                  ) : (
                    <FormattedMessage id="failToCreateQR" />
                  )}
                </div>
              </div>{" "}
              <button onClick={handleCaptureShare}>
                <FormattedMessage id="saveQRasimage" />
              </button>
              <div>
                <FormattedMessage id="or" />
              </div>
              <button onClick={copyCurrentUrl}>
                <FormattedMessage id="shareTextLink" />
              </button>
              <button onClick={closeModal} style={{ width: "fit-content" }}>
                <FormattedMessage id="close" />
              </button>
            </div>
          </Modal>
        </div>{" "}
        <br />
      </div>
      <div
        style={{
          // visibility: "hidden",
          position: "absolute",
          // width: "",
          top: -9999,
          left: -9999,
        }}
        ref={ChartandNameRef}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "flex-end",
            padding: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // width: "80%",
              // maxWidth: 1000,
              gap: 10,
              // visibility: "hidden",
              // position: "absolute",
              // top: -9999,
              // left: -9999,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {colorPallette.map((color, idx) => (
                <div key={idx}>
                  <div>
                    <div
                      style={{
                        background: fixedColorPallette[idx],
                        width: 40,
                        height: 10,
                        border: "1px solid grey",
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
            <div className="centered">
              <div
                style={{
                  // maxWidth: windowWidth * 0.8,

                  paddingTop: 2,
                }}
              >
                {pixelArrData.map((i, index_i) => (
                  <div
                    key={index_i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: size * pixelHeight[pixelShape],
                      width: "fit-content",
                      boxSizing: "border-box",
                      marginLeft: 2,
                      marginRight: 2,
                    }}
                    onPointerDown={() => {
                      if (currentRow === index_i) {
                        setCurrentRow(null);
                      } else {
                        setCurrentRow(index_i);
                      }
                    }}
                  >
                    <div
                      style={{
                        width: size,
                        minWidth: size,
                        height: "100%",
                        outline: "1px solid grey",
                        backgroundColor: "white",
                      }}
                      className="noDrag centered"
                    >
                      {pixelArrData.length - index_i}
                    </div>
                    {i.map((j, index_j) => (
                      <PixelWrapper
                        key={index_i + "_" + index_j}
                        isMouseDown={isMouseDown}
                        size={size}
                        currentColor={pixelArrData[index_i][index_j]}
                        pixelArrData={pixelArrData}
                        x={index_j}
                        y={index_i}
                        setIsMouseDown={setIsMouseDown}
                        colorPallette={colorPallette}
                        pixelShape={pixelShape}
                      ></PixelWrapper>
                    ))}
                    <div
                      style={{
                        width: size,
                        minWidth: size,
                        height: "100%",
                        outline: "1px solid grey",
                        backgroundColor: "white",

                        boxSizing: "border-box",
                      }}
                      className="noDrag centered"
                    >
                      {pixelArrData.length - index_i}
                    </div>
                  </div>
                ))}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      width: size,
                      minWidth: size,

                      height: size,
                      outline: "1px solid grey",
                      boxSizing: "border-box",
                      backgroundColor: "white",

                      marginLeft: 2,
                      marginBottom: 2,
                    }}
                  ></div>
                  {Array.from(
                    { length: pixelArrData[0].length },
                    (_, index) => index
                  ).map((_, idx) => (
                    <div
                      key={idx}
                      style={{
                        width: size,
                        minWidth: size,
                        height: size,
                        outline: "1px solid grey",
                        boxSizing: "border-box",
                        backgroundColor: "white",
                      }}
                      className="noDrag centered"
                    >
                      {pixelArrData[0].length - idx}
                    </div>
                  ))}
                  <div
                    style={{
                      width: size,
                      minWidth: size,
                      height: size,
                      outline: "1px solid grey",
                      boxSizing: "border-box",
                      backgroundColor: "white",
                      marginRight: 2,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div className="title-txt">{title}</div>
            <div className="common-txt">{name}</div>
            <br></br>
            <div>
              <div>knitshare.link</div>
              <div>
                {QRCodeMaker() ? (
                  <QRCodeMaker />
                ) : (
                  <FormattedMessage id="failToCreateQR" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </IntlProvider>
  );
}

function PixelWrapper(props) {
  // console.log(props.isFifthRowShown);
  return (
    <div
      style={{
        boxShadow: props.isFifthRowShown
          ? props.y % 5 === 0
            ? "0 -3px 0 gray"
            : "none"
          : "none",
      }}
    >
      <Pixel
        size={props.size}
        currentColor={props.currentColor}
        pixelArrData={props.pixelArrData}
        x={props.x}
        y={props.y}
        colorPallette={props.colorPallette}
        pixelShape={props.pixelShape}
      ></Pixel>
    </div>
  );
}

export default PixelDisplayer;
