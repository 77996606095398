import React from "react";

const HalfDoubleCrochetShape = (props) => {
  return (
    <div style={{ width: props.size, height: (props.size / 2) * 3 }}>
      <svg viewBox="0 0 400 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7_2)">
          <rect
            x="157.51"
            y="623.77"
            width="147.048"
            height="614.953"
            rx="73.5238"
            transform="rotate(-155.139 157.51 623.77)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
          <rect
            x="418.503"
            y="353.51"
            width="155.307"
            height="462.583"
            rx="77.6535"
            transform="rotate(132.124 418.503 353.51)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
          <rect
            x="415.503"
            y="524.51"
            width="155.307"
            height="462.583"
            rx="77.6535"
            transform="rotate(132.124 415.503 524.51)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
          <rect
            x="397"
            y="3"
            width="150"
            height="394"
            rx="75"
            transform="rotate(90 397 3)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
        </g>
        <defs>
          <clipPath id="clip0_7_2">
            <rect width="400" height="600" fill="none" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HalfDoubleCrochetShape;
