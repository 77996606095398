import React from "react";

function Footer() {
  return (
    <div className="common-txt">
      This site is made by gongboo<a href="/info">ⓘ</a>
    </div>
  );
}

export default Footer;
