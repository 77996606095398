import React from "react";

const GlossBeadShape = (props) => {
  return (
    <div
      style={{
        width: props.size - 1,
        height: props.size,
        background:
          "linear-gradient(rgba(255, 255, 255, 0.3), rgba(107, 107, 107, 0.3)), " +
          props.color,

        border: "1px solid grey",
        boxSizing: "border-box",
        borderRadius: "50%",
        margin: "0px 1px 0px 0px",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "20%",
          left: "20%",
          width: "30%",
          height: "30%",
          background:
            "radial-gradient(circle at 50%, rgba(255, 255, 255, 0.7), transparent)",
          // background: "rgba(255, 255, 255, 0.7)",
          boxSizing: "border-box",
          borderRadius: "70%",
          margin: "0px",
        }}
      ></div>
    </div>
  );
};

export default GlossBeadShape;
