import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "./messages";
import Footer from "./Footer";
import useWindowSize from "./useWindowSize";
import Vshape from "./shapes/Vshape";
import SingleCrochetShape from "./shapes/SingleCrochetShape";
import HalfDoubleCrochetShape from "./shapes/HalfDoubleCrochetShape";
import DoubleCrochetShape from "./shapes/DoubleCrochetShape";
import GlossBeadShape from "./shapes/GlossBeadShape";
import BeadShape from "./shapes/BeadShape";
import RealVshape from "./shapes/RealVShape";

import { bytesToBase64, pixelHeight, width, height, size } from "./utils";
import Pixel from "./Pixel";
import RealSingleCrochetShape from "./shapes/RealSingleCrochetShape";
import RealHalfDoubleCrochetShape from "./shapes/RealHalfDoubleCrochetShape";
import RealDoubleCrochetShape from "./shapes/RealDoubleCrochetShape";

import { kmeans } from "ml-kmeans";
import convert from "color-convert";

function PixelMaker(props) {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const menuWidth =
    windowWidth * 0.4 < 300 ? windowWidth * 0.8 : windowWidth * 0.4;

  const userLanguage = navigator.language.split("-")[0];

  const locale =
    userLanguage === "ko" || userLanguage === "ja" ? userLanguage : "en";

  const navigate = useNavigate();

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [selectedColor, setSelectedColor] = useState(1);
  const [pixelShape, setpixelShape] = useState("sq");
  const [pixelArrData, setPixelArrData] = useState(
    Array.from({ length: height }, () => new Array(width).fill(0))
  );

  const [colorPallette, setcolorPallette] = useState(["#000000", "#ffffff"]);
  useEffect(() => {}, [pixelArrData]);

  const [title, setTitle] = useState("");
  const [name, setName] = useState("");

  const handlePixelShapeOptionChange = (event) => {
    setpixelShape(event.target.value);
  };

  const handlePalletteOptionChange = (event) => {
    setSelectedColor(event.target.value);
  };
  const rowIncrease = () => {
    let rowAddedData = [...pixelArrData];
    rowAddedData.push(new Array(rowAddedData[0].length).fill(0));
    setPixelArrData(rowAddedData);
  };
  const rowDecrease = () => {
    let rowAddedData = [...pixelArrData];
    rowAddedData.pop();
    setPixelArrData(rowAddedData);
  };

  const colIncrease = () => {
    let rowAddedData = [...pixelArrData];
    for (let i = 0; i < rowAddedData.length; i++) {
      rowAddedData[i].push(0);
    }
    setPixelArrData(rowAddedData);
  };
  const colDecrease = () => {
    let rowAddedData = [...pixelArrData];
    for (let i = 0; i < rowAddedData.length; i++) {
      rowAddedData[i].pop();
    }
    setPixelArrData(rowAddedData);
  };

  const arrDataChanger = (x, y) => {
    let updatedPixels = [...pixelArrData];
    updatedPixels[y][x] = selectedColor;
    setPixelArrData(updatedPixels);
  };

  const pushPallette = () => {
    let tmp = [...colorPallette];
    tmp.push("#ffffff");
    setcolorPallette(tmp);
  };
  const popPallette = () => {
    let tmp = [...colorPallette];
    tmp.pop();
    setcolorPallette(tmp);
  };
  const handleColorChange = (idx, color) => {
    let tmp = [...colorPallette];
    tmp[idx] = color;
    setcolorPallette(tmp);
  };

  const getcolorpallette = () => {
    return colorPallette.join("|");
  };

  const getNumberArray = () => {
    let rows = [];
    for (let i = 0; i < pixelArrData.length; i++) {
      rows.push(pixelArrData[i].join("|"));
    }
    return rows.join("%");
  };
  const [pixelArrDataHistory, setPixelArrDataHistory] = useState(
    Array.from({ length: 1 }, () =>
      Array.from({ length: height }, () => new Array(width).fill(0))
    )
  );

  const [isImageMenuOpen, setIsImageMenuOpen] = useState(false);

  const handleImageMenu = () => {
    setIsImageMenuOpen(!isImageMenuOpen);
  };

  const [historyPointer, setHistoryPointer] = useState(0);
  const handleUndo = () => {
    if (historyPointer - 1 >= 0) {
      const nextState = pixelArrDataHistory[historyPointer - 1].map((row) => [
        ...row,
      ]);
      setHistoryPointer(historyPointer - 1);

      setPixelArrData(nextState);
    }
    // console.log("pixelArrData", pixelArrData);
    // console.log(
    //   "pixelArrDataHistory",
    //   pixelArrDataHistory,
    //   "historyPointer",
    //   historyPointer
    // );
  };

  const handleRedo = () => {
    if (historyPointer + 1 <= pixelArrDataHistory.length - 1) {
      const nextState = pixelArrDataHistory[historyPointer + 1].map((row) => [
        ...row,
      ]);
      setHistoryPointer(historyPointer + 1);

      setPixelArrData(nextState);
    }
    // console.log("pixelArrData", pixelArrData);
    // console.log(
    //   "pixelArrDataHistory",
    //   pixelArrDataHistory,
    //   "historyPointer",
    //   historyPointer
    // );
  };

  const handleAddToHistory = () => {
    let tmp = [...pixelArrDataHistory];
    //히스토리 현시점 뒤의 기록이 있으면 다 지운다.
    if (historyPointer < pixelArrDataHistory.length - 1) {
      tmp.splice(historyPointer + 1, pixelArrDataHistory.length - 1);
    }
    let current_arr_tmp = pixelArrData.map((innerArray) => [...innerArray]);
    tmp.push(current_arr_tmp);
    setPixelArrDataHistory(tmp);
    setHistoryPointer(historyPointer + 1);
    // console.log("tmp", tmp, "historyPointer", historyPointer);

    // setPixelArrData(pixelArrDataHistory[historyPointer]);
  };

  const makeLink = () => {
    //버전별로 다르게 처리할 필요가 있다
    let next_link =
      "/share/?colors=" +
      encodeURIComponent(bytesToBase64(String(getcolorpallette()))) +
      "&arr=" +
      encodeURIComponent(bytesToBase64(String(getNumberArray()))) +
      "&pxlshp=" +
      encodeURIComponent(bytesToBase64(String(pixelShape))) +
      "&title=" +
      encodeURIComponent(bytesToBase64(String(title))) +
      "&name=" +
      encodeURIComponent(bytesToBase64(String(name)));
    if (next_link.length > 30000) {
      alert("Too long url. please make a smaller pattern");
    } else {
      navigate(next_link);
    }
  };

  //------------------------- picture to pixel
  const [imageSrc, setImageSrc] = useState(null);

  const canvas_small_Ref = useRef(null);
  const canvas_pallette_Ref = useRef(null);

  const [pictureArrData, setPictureArrData] = useState(
    Array.from({ length: height }, () => new Array(width).fill(0))
  );
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  function getCIE76Distance(color1, color2) {
    const lab1 = convert.rgb.lab(color1[0], color1[1], color1[2]);
    const lab2 = convert.rgb.lab(color2[0], color2[1], color2[2]);

    const lDiff = lab2[0] - lab1[0];
    const aDiff = lab2[1] - lab1[1];
    const bDiff = lab2[2] - lab1[2];

    return Math.sqrt(lDiff * lDiff + aDiff * aDiff + bDiff * bDiff);
  }

  const getClosest = (pallette_lst, rgb) => {
    let tmp_result = [];
    for (let i = 0; i < pallette_lst.length; i++) {
      let current_pallette = pallette_lst[i];
      tmp_result.push(getCIE76Distance(current_pallette, rgb));
    }
    const minValue = Math.min(...tmp_result); // 배열의 최소값을 구함
    const minIndex = tmp_result.indexOf(minValue); // 그 값의 인덱스를 찾음

    return minIndex;
  };
  function rgbToHex(r, g, b) {
    return (
      "#" +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    );
  }

  const handleImageResize = () => {
    const pixel_canvas = canvas_small_Ref.current; //dom으로 접근해서 가져오고
    const pallette_canvas = canvas_pallette_Ref.current;
    const ctx_small = pixel_canvas.getContext("2d"); //2d로 가져온다.
    const ctx_pallette = pallette_canvas.getContext("2d");
    const img = new Image();
    const colorPallette_count = colorPallette.length;
    const row_num = pixelArrData[0].length;
    const col_num = pixelArrData.length;

    img.src = imageSrc;
    img.onload = () => {
      // const scale = 0.1; // 축소 비율 설정
      // const newWidth = img.width * scale;
      // const newHeight = img.height * scale;

      pixel_canvas.width = row_num;
      pixel_canvas.height = col_num;

      // Canvas에 축소된 이미지 그리기
      ctx_small.clearRect(0, 0, pixel_canvas.width, pixel_canvas.height); // 초기화

      ctx_small.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        row_num,
        col_num
      );

      pallette_canvas.width = 400;
      pallette_canvas.height = 400;
      ctx_pallette.clearRect(
        0,
        0,
        pallette_canvas.width,
        pallette_canvas.height
      ); // 초기화

      // Canvas에 축소된 이미지 그리기
      ctx_pallette.drawImage(img, 0, 0, img.width, img.height, 0, 0, 400, 400);

      //팔레트이미지에서 팔레트 추출
      //일단 픽셀들을 리스트로 만든다

      let pallette_pixels_lst = [];
      for (let y = 0; y < 400; y++) {
        for (let x = 0; x < 400; x++) {
          // (x, y) 위치의 픽셀 데이터 가져오기
          const imageData = ctx_pallette.getImageData(x, y, 1, 1);
          const [r, g, b, a] = imageData.data; // RGBA 값
          pallette_pixels_lst.push([r, g, b]);
        }
      }

      //픽셀들을 Ml kmeans 돌림
      // K-means 알고리즘 실행 (클러스터 개수: 3)
      const kmeansResult = kmeans(pallette_pixels_lst, colorPallette_count);

      // 결과 출력 (클러스터 할당)
      console.log(kmeansResult.centroids);
      const final_pallette = kmeansResult.centroids.map((coordinate) =>
        coordinate.map((value) => Math.floor(value))
      );
      let final_pixel = [];
      for (let y = 0; y < col_num; y++) {
        let row = [];
        for (let x = 0; x < row_num; x++) {
          const imageData = ctx_small.getImageData(x, y, 1, 1);
          const [r, g, b, a] = imageData.data;
          const min_idx = getClosest(final_pallette, [r, g, b]);
          row.push(min_idx);
          // row.push(
          //   "rgb(" +
          //     String(final_pallette[min_idx][0]) +
          //     "," +
          //     String(final_pallette[min_idx][1]) +
          //     "," +
          //     String(final_pallette[min_idx][2]) +
          //     ")"
          // );
        }
        final_pixel.push(row);
      }

      const hexArray = final_pallette.map(([r, g, b]) => rgbToHex(r, g, b));
      // 팔레트를 기준으로 픽셀이미지를 통해 배열을 생성해서 반환...
      console.log(final_pixel);
      setcolorPallette(hexArray);
      setPictureArrData(final_pixel);
      setPixelArrData(final_pixel);
      // return final_pixel;
    };
  };

  //-------------------------
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="centered">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="title-txt">
            <FormattedMessage id="title" />
          </div>

          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexFlow: "wrap",
              gap: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                width: menuWidth,
                maxWidth: menuWidth,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: menuWidth,
                  maxWidth: menuWidth,
                }}
                className="middle-txt"
              >
                <div style={{ marginRight: 10 }}>
                  <FormattedMessage id="titleMenu" />
                </div>
                <input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  maxLength={20}
                  // style={{ width: menuWidth, maxWidth: menuWidth }}
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className="middle-txt"
              >
                {" "}
                <div style={{ marginRight: 10 }}>
                  <FormattedMessage id="madeByMenu" />
                </div>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  maxLength={20}
                  // style={{ width: menuWidth, maxWidth: menuWidth }}
                />
              </div>
              <div className="middle-txt">
                {" "}
                <FormattedMessage id="pixelShapeMenu" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: 10,
                  textAlign: "center",
                  overflowX: "scroll",
                }}
              >
                {/* pixel shape */}
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: size,
                      height: size,
                      backgroundColor: "lightsteelblue",
                      border: "1px solid grey",
                      boxSizing: "border-box",
                    }}
                  ></div>
                  <div className="shape-select">
                    <FormattedMessage id="pixelShapeSquare" />
                  </div>
                  <input
                    type="radio"
                    id="option1"
                    name="example"
                    value="sq"
                    checked={pixelShape === "sq"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Vshape color="lightsteelblue" size={size}></Vshape>
                  <div className="shape-select">
                    {" "}
                    <FormattedMessage id="pixelShapeKnitStitch" />
                  </div>

                  <input
                    type="radio"
                    id="option2"
                    name="example"
                    value="v"
                    checked={pixelShape === "v"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SingleCrochetShape
                    color="lightsteelblue"
                    size={size}
                  ></SingleCrochetShape>
                  <div className="shape-select">
                    <FormattedMessage id="pixelShapeSingleCrochet" />
                  </div>

                  <input
                    type="radio"
                    id="option2"
                    name="example"
                    value="sc"
                    checked={pixelShape === "sc"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <HalfDoubleCrochetShape
                    color="lightsteelblue"
                    size={size}
                  ></HalfDoubleCrochetShape>
                  <div className="shape-select">
                    {" "}
                    <FormattedMessage id="pixelShapeHalfDoubleCrochet" />
                  </div>

                  <input
                    type="radio"
                    id="option2"
                    name="example"
                    value="hdc"
                    checked={pixelShape === "hdc"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DoubleCrochetShape
                    color="lightsteelblue"
                    size={size}
                  ></DoubleCrochetShape>
                  <div className="shape-select">
                    {" "}
                    <FormattedMessage id="pixelShapeDoubleCrochet" />
                  </div>

                  <input
                    type="radio"
                    id="option2"
                    name="example"
                    value="dc"
                    checked={pixelShape === "dc"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>{" "}
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <GlossBeadShape
                    color="lightsteelblue"
                    size={size}
                  ></GlossBeadShape>
                  <div className="shape-select">
                    <FormattedMessage id="pixelShapeGlossBeads" />
                  </div>
                  <input
                    type="radio"
                    id="option6"
                    name="example"
                    value="bd1"
                    checked={pixelShape === "bd1"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>{" "}
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BeadShape color="lightsteelblue" size={size}></BeadShape>
                  <div className="shape-select">
                    <FormattedMessage id="pixelShapeBeads" />
                  </div>
                  <input
                    type="radio"
                    id="option6"
                    name="example"
                    value="bd2"
                    checked={pixelShape === "bd2"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RealVshape color="lightsteelblue" size={size}></RealVshape>
                  <div className="shape-select">
                    <FormattedMessage id="pixelShapeRealKnitStitch" />
                  </div>
                  <input
                    type="radio"
                    id="option6"
                    name="example"
                    value="rv"
                    checked={pixelShape === "rv"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RealSingleCrochetShape
                    color="lightsteelblue"
                    size={size}
                  ></RealSingleCrochetShape>
                  <div className="shape-select">
                    <FormattedMessage id="pixelShapeRealSingleCrochet" />
                  </div>
                  <input
                    type="radio"
                    id="option6"
                    name="example"
                    value="rsc"
                    checked={pixelShape === "rsc"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RealHalfDoubleCrochetShape
                    color="lightsteelblue"
                    size={size}
                  ></RealHalfDoubleCrochetShape>
                  <div className="shape-select">
                    <FormattedMessage id="pixelShapeRealHalfDoubleCrochet" />
                  </div>
                  <input
                    type="radio"
                    id="option6"
                    name="example"
                    value="rhdc"
                    checked={pixelShape === "rhdc"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>{" "}
                <div
                  style={{
                    width: menuWidth * 0.2,
                    maxWidth: menuWidth * 0.2,
                    flexWrap: "wrap",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RealDoubleCrochetShape
                    color="lightsteelblue"
                    size={size}
                  ></RealDoubleCrochetShape>
                  <div className="shape-select">
                    <FormattedMessage id="pixelShapeRealDoubleCrochet" />
                  </div>
                  <input
                    type="radio"
                    id="option6"
                    name="example"
                    value="rdc"
                    checked={pixelShape === "rdc"}
                    onChange={handlePixelShapeOptionChange}
                  />
                </div>
              </div>
            </div>
            <br />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: menuWidth,
                width: menuWidth,
              }}
            >
              {/* inc dec buttons */}
              {/* sq, v, sc, hdc, dc */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 10,
                }}
              >
                <button
                  onClick={rowIncrease}
                  className="common-txt menu-select"
                >
                  <FormattedMessage id="addRow" />
                </button>
                <button
                  onClick={rowDecrease}
                  className="common-txt menu-select"
                >
                  <FormattedMessage id="deleteRow" />
                </button>
                <button
                  onClick={colIncrease}
                  className="common-txt menu-select"
                >
                  <FormattedMessage id="addCol" />
                </button>
                <button
                  onClick={colDecrease}
                  className="common-txt menu-select"
                >
                  <FormattedMessage id="deleteCol" />
                </button>
                <button onClick={handleUndo} className="common-txt menu-select">
                  <span
                    className="material-symbols-outlined"
                    aria-hidden="true"
                    translate="no"
                  >
                    undo
                  </span>
                  <FormattedMessage id="undo" />
                </button>

                <button onClick={handleRedo} className="common-txt menu-select">
                  <span
                    className="material-symbols-outlined"
                    aria-hidden="true"
                    translate="no"
                  >
                    redo
                  </span>
                  <FormattedMessage id="redo" />
                </button>
              </div>
              <br />
              {/* color pallette */}
              <div className="middle-txt">
                <FormattedMessage id="colorPalletteMenu" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  maxWidth: menuWidth,
                  width: menuWidth,
                  gap: 10,
                }}
              >
                {colorPallette.map((color, idx) => (
                  <div key={idx}>
                    <div
                      style={{
                        border:
                          selectedColor == idx
                            ? "2px solid red"
                            : "2px solid transparent",
                        display: "flex",
                        flexDirection: "column",
                        minWidth: 40,
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                      onClick={() => setSelectedColor(idx)}
                    >
                      <input
                        type="color"
                        value={colorPallette[idx]}
                        style={{ minWidth: 50, marginBottom: 10 }}
                        onChange={(e) => {
                          handleColorChange(idx, e.target.value);
                        }}
                      ></input>
                      <input
                        type="radio"
                        id={"pallette" + idx}
                        name="pallette"
                        value={idx}
                        checked={selectedColor === idx}
                        // onChange={handlePalletteOptionChange}
                      />
                    </div>
                  </div>
                ))}

                <a onClick={pushPallette} className="noDrag">
                  <span
                    className="material-symbols-outlined"
                    aria-hidden="true"
                    translate="no"
                    style={{ fontSize: 30, marginTop: 10 }}
                  >
                    add_circle
                  </span>
                </a>
                <a onClick={popPallette} className="noDrag">
                  <span
                    class="material-symbols-outlined"
                    aria-hidden="true"
                    translate="no"
                    style={{ fontSize: 30, marginTop: 10 }}
                  >
                    do_not_disturb_on
                  </span>
                </a>
              </div>{" "}
              <br />
              <div
                onClick={handleImageMenu}
                style={{ border: "1px solid transparent" }}
                className="noDrag"
              >
                {isImageMenuOpen ? (
                  <div
                    style={{
                      backgroundColor: "darkgray",
                      alignContent: "center",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      aria-hidden="true"
                      translate="no"
                      style={{ color: "gray" }}
                    >
                      image
                    </span>
                    <FormattedMessage id="imageToPixel" />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      aria-hidden="true"
                      translate="no"
                      style={{ color: "gray" }}
                    >
                      image
                    </span>
                    <FormattedMessage id="imageToPixel" />
                  </div>
                )}
              </div>
              {isImageMenuOpen && (
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  <FormattedMessage id="imageToPixelComment" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                  </div>
                  <button onClick={handleImageResize}>
                    <FormattedMessage id="createImageToPixel" />
                  </button>

                  <div>
                    <canvas
                      ref={canvas_small_Ref}
                      style={{ display: "none" }}
                    ></canvas>
                    <canvas
                      ref={canvas_pallette_Ref}
                      style={{ display: "none" }}
                    ></canvas>
                  </div>
                </div>
              )}
            </div>
          </div>
          <br />
          {/* pixel board */}
          <div className="centered">
            <div
              style={{
                maxWidth: windowWidth * 0.8,

                overflowX: "auto",
                overflowY: "hidden",
                // boxSizing: "border-box",
              }}
            >
              {pixelArrData.map((i, index_i) => (
                <div
                  key={index_i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: size * pixelHeight[pixelShape],
                  }}
                >
                  <div
                    style={{
                      minWidth: size,
                      border: "1px solid grey",
                      boxSizing: "border-box",
                    }}
                    className="noDrag centered common-txt"
                  >
                    {pixelArrData.length - index_i}
                  </div>
                  {i.map((j, index_j) => (
                    <PixelWrapper
                      selectedColor={selectedColor}
                      key={index_i + "_" + index_j}
                      isMouseDown={isMouseDown}
                      size={size}
                      currentColor={pixelArrData[index_i][index_j]}
                      pixelArrData={pixelArrData}
                      x={index_j}
                      y={index_i}
                      arrDataChanger={arrDataChanger}
                      setIsMouseDown={setIsMouseDown}
                      colorPallette={colorPallette}
                      pixelShape={pixelShape}
                      handleAddToHistory={handleAddToHistory}
                    ></PixelWrapper>
                  ))}
                  <div
                    style={{
                      minWidth: size,
                      height: "100%",
                      border: "1px solid grey",
                      boxSizing: "border-box",
                    }}
                    className="noDrag centered common-txt"
                  >
                    {pixelArrData.length - index_i}
                  </div>
                </div>
              ))}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    minWidth: size,
                    height: size,
                    border: "1px solid grey",
                    boxSizing: "border-box",
                  }}
                ></div>
                {Array.from(
                  { length: pixelArrData[0].length },
                  (_, index) => index
                ).map((_, idx) => (
                  <div
                    key={idx}
                    style={{
                      minWidth: size,
                      height: size,
                      border: "1px solid grey",
                      boxSizing: "border-box",
                    }}
                    className="noDrag centered common-txt"
                  >
                    {pixelArrData[0].length - idx}
                  </div>
                ))}
                <div
                  style={{
                    minWidth: size,
                    height: size,
                    border: "1px solid grey",
                    boxSizing: "border-box",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <br />
          <button onClick={makeLink} className="common-txt">
            <FormattedMessage id="create" />
          </button>
          <br />
          <Footer />
        </div>
      </div>
    </IntlProvider>
  );
}

function PixelWrapper(props) {
  const [color, setColor] = React.useState(props.colorPallette[0]);

  const handleMouseOver = () => {
    if (props.isMouseDown) {
      setColor(props.colorPallette[props.selectedColor]);
      props.arrDataChanger(props.x, props.y);
    }
  };

  const handleMouseDown = () => {
    setColor(props.colorPallette[props.selectedColor]);
    props.arrDataChanger(props.x, props.y);
    props.setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    props.setIsMouseDown(false);
    props.handleAddToHistory();
  };

  return (
    <div
      onPointerOver={handleMouseOver}
      onPointerDown={handleMouseDown}
      onPointerUp={handleMouseUp}
    >
      <Pixel
        size={props.size}
        currentColor={props.currentColor}
        pixelArrData={props.pixelArrData}
        x={props.x}
        y={props.y}
        colorPallette={props.colorPallette}
        pixelShape={props.pixelShape}
      ></Pixel>
    </div>
  );
}

export default PixelMaker;
