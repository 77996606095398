import React from "react";

const BeadShape = (props) => {
  return (
    <div
      style={{
        width: props.size - 1,
        height: props.size,
        background:
          "linear-gradient(rgba(255, 255, 255, 0.3), rgba(107, 107, 107, 0.3)), " +
          props.color,
        // backgroundColor:
        //   props.colorPallette[props.pixelArrData[props.y][props.x]],
        border: "1px solid grey",
        boxSizing: "border-box",
        borderRadius: "50%",
        margin: "0px 1px 0px 0px",
        position: "relative",
        // background: linear-gradient(#e66465, #9198e5);
      }}
    ></div>
  );
};

export default BeadShape;
