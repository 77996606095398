import React from "react";

const SingleCrochetShape = (props) => {
  return (
    <div style={{ width: props.size, height: props.size }}>
      <svg viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3_14)">
          <rect
            x="228.64"
            y="383.559"
            width="147.048"
            height="347.772"
            rx="73.5238"
            transform="rotate(166.34 228.64 383.559)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
          <rect
            x="250.404"
            y="423.293"
            width="147.048"
            height="394"
            rx="73.5238"
            transform="rotate(-155.139 250.404 423.293)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
          <rect
            x="397"
            y="3"
            width="150"
            height="394"
            rx="75"
            transform="rotate(90 397 3)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_14">
            <rect width="400" height="400" fill="none" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default SingleCrochetShape;
