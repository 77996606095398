import React, { useEffect, useState } from "react";
import Vshape from "./shapes/Vshape";
import SingleCrochetShape from "./shapes/SingleCrochetShape";
import HalfDoubleCrochetShape from "./shapes/HalfDoubleCrochetShape";
import DoubleCrochetShape from "./shapes/DoubleCrochetShape";
import GlossBeadShape from "./shapes/GlossBeadShape";
import BeadShape from "./shapes/BeadShape";
import Squareshape from "./shapes/SquareShape";

import RealVshape from "./shapes/RealVShape";
import RealSingleCrochetshape from "./shapes/RealSingleCrochetShape";
import RealHalfDoubleCrochetshape from "./shapes/RealHalfDoubleCrochetShape";
import RealDoubleCrochetshape from "./shapes/RealDoubleCrochetShape";

function Pixel(props) {
  return (
    <div>
      {/* sq, v, sc, hdc, dc */}
      {props.pixelShape === "sq" && (
        <Squareshape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></Squareshape>
      )}
      {props.pixelShape === "v" && (
        <Vshape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></Vshape>
      )}
      {props.pixelShape === "sc" && (
        <SingleCrochetShape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></SingleCrochetShape>
      )}
      {props.pixelShape === "hdc" && (
        <HalfDoubleCrochetShape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></HalfDoubleCrochetShape>
      )}
      {props.pixelShape === "dc" && (
        <DoubleCrochetShape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></DoubleCrochetShape>
      )}{" "}
      {props.pixelShape === "bd1" && (
        <GlossBeadShape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></GlossBeadShape>
      )}{" "}
      {props.pixelShape === "bd2" && (
        <BeadShape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></BeadShape>
      )}{" "}
      {props.pixelShape === "rv" && (
        <RealVshape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></RealVshape>
      )}{" "}
      {props.pixelShape === "rsc" && (
        <RealSingleCrochetshape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></RealSingleCrochetshape>
      )}{" "}
      {props.pixelShape === "rhdc" && (
        <RealHalfDoubleCrochetshape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></RealHalfDoubleCrochetshape>
      )}{" "}
      {props.pixelShape === "rdc" && (
        <RealDoubleCrochetshape
          color={props.colorPallette[props.pixelArrData[props.y][props.x]]}
          size={props.size}
        ></RealDoubleCrochetshape>
      )}
    </div>
  );
}

export default Pixel;
