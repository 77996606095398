import React from "react";

const DoubleCrochetShape = (props) => {
  return (
    <div style={{ width: props.size, height: props.size * 2 }}>
      <svg viewBox="0 0 400 800" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8_13)">
          <rect
            x="360"
            y="797"
            width="155"
            height="757"
            rx="77.5"
            transform="rotate(-180 360 797)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
          <rect
            x="197"
            y="797"
            width="155"
            height="757"
            rx="77.5"
            transform="rotate(-180 197 797)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
          <rect
            x="399"
            y="3"
            width="150"
            height="394"
            rx="75"
            transform="rotate(90 399 3)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
          <rect
            x="423.503"
            y="515.51"
            width="155.307"
            height="462.583"
            rx="77.6535"
            transform="rotate(132.124 423.503 515.51)"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
        </g>
        <defs>
          <clipPath id="clip0_8_13">
            <rect width="400" height="800" fill="none" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default DoubleCrochetShape;
