import pako from "pako";

// src/compression.js
export function base64ToBytes(str) {
  try {
    const binString = atob(str);
    const charData = Array.from(binString).map((char) => char.charCodeAt(0));
    const byteArray = Uint8Array.from(charData);
    return new TextDecoder().decode(pako.inflate(byteArray));
  } catch (e) {
    console.error("Decompression error:", e);
    return undefined;
  }
}

export function bytesToBase64(str) {
  try {
    const byteArray = new TextEncoder().encode(str);
    const compressed = pako.deflate(byteArray);
    const binString = Array.from(compressed)
      .map((byte) => String.fromCharCode(byte))
      .join("");
    return btoa(binString);
  } catch (e) {
    console.error("Compression error:", e);
    return undefined;
  }
}
export const pixelHeight = {
  sq: 1,
  v: 1,
  sc: 1,
  hdc: 1.5,
  dc: 2,
  bd1: 1,
  bd2: 1,
  rv: 1,
  rsc: 1,
  rhdc: 1.5,
  rdc: 2,
};

export const width = 10;
export const height = 10;
export const size = 20;

// export function base64ToBytes(str) {
//   try {
//     const binString = atob(str);
//     return new TextDecoder().decode(
//       pako.inflate(Uint8Array.from(binString, (m) => m.codePointAt(0)))
//     );
//   } catch (e) {
//     console.error("Decompression error:", e);
//     return undefined;
//   }
// }

// export function bytesToBase64(str) {
//   const binString = Array.from(new TextEncoder().encode(str), (x) =>
//     String.fromCodePoint(x)
//   ).join("");
//   return btoa(pako.deflate(binString));
// }

// export function base64ToBytes(str) {
//   try {
//     const binString = atob(str);
//     return new TextDecoder().decode(
//       Uint8Array.from(binString, (m) => m.codePointAt(0))
//     );
//   } catch (e) {
//     console.error("Decompression error:", e);
//     return undefined;
//   }
// }

// export function bytesToBase64(str) {
//   const binString = Array.from(new TextEncoder().encode(str), (x) =>
//     String.fromCodePoint(x)
//   ).join("");
//   return btoa(binString);
// }
