const messages = {
  en: {
    title: "Make color chart for knitting",
    titleMenu: "Title",
    madeByMenu: "Made by",
    pixelShapeMenu: "Pixel Shape",
    pixelShapeSquare: "Square",
    pixelShapeKnitStitch: "Knit Stitch",
    pixelShapeSingleCrochet: "Single Crochet",
    pixelShapeHalfDoubleCrochet: "Half Double Crochet",
    pixelShapeDoubleCrochet: "Double Crochet",
    pixelShapeGlossBeads: "gloss beads",
    pixelShapeBeads: "beads",
    pixelShapeRealKnitStitch: "Real Knit Stitch",
    pixelShapeRealSingleCrochet: "Real Single Crochet",
    pixelShapeRealHalfDoubleCrochet: "Real Half Double Crochet",
    pixelShapeRealDoubleCrochet: "Real Double Crochet",
    addRow: "Add Row",
    deleteRow: "Delete Row",
    addCol: "Add Column",
    deleteCol: "Delete Column",
    colorPalletteMenu: "Color Pallette",
    create: "create",
    share: "share",
    or: "or",
    shareTextQR: "share this QR to your friends!",
    shareTextLink:
      "Click here to copy this URL and share it with your friends!",
    failToCreateQR: "Failed to create QR code. The link is too long",
    close: "close",
    makeNewOne: "Make a new one!",
    savechartasimage: "save this chart as image",
    saveQRasimage: "save this QR as image",
    isFifthRowShown: "Fifth row bold",
    imageToPixel: "image → pattern",
    createImageToPixel: "Convert the input image into a knitting pattern.",
    imageToPixelComment:
      "Please set the number of colors, rows, and columns before converting the image!",
    undo: "undo",
    redo: "redo",
  },
  ko: {
    title: "뜨개 색상 도안 만들기",
    titleMenu: "제목",
    madeByMenu: "작성자",
    pixelShapeMenu: "모양",
    pixelShapeSquare: "네모",
    pixelShapeKnitStitch: "겉뜨기",
    pixelShapeSingleCrochet: "짧은뜨기",
    pixelShapeHalfDoubleCrochet: "긴뜨기",
    pixelShapeDoubleCrochet: "한길긴뜨기",
    pixelShapeRealKnitStitch: "진짜 겉뜨기",
    pixelShapeRealSingleCrochet: "진짜 짧은뜨기",
    pixelShapeRealHalfDoubleCrochet: "진짜 긴뜨기",
    pixelShapeRealDoubleCrochet: "진짜 한길긴뜨기",
    addRow: "행 추가",
    deleteRow: "행 삭제",
    addCol: "열 추가",
    deleteCol: "열 삭제",
    colorPalletteMenu: "컬러파레트",
    create: "생성하기",
    share: "공유하기",
    or: "또는",
    shareTextQR: "이 QR을 친구들과 공유하세요!",
    shareTextLink: "여기를 클릭하여 이 URL을 복사하고 친구들과 공유하세요!",
    failToCreateQR: "링크가 너무 길어 QR 생성에 실패하였습니다. ",
    close: "닫기",
    makeNewOne: "새로 만들어 보세요!",
    pixelShapeGlossBeads: "반짝 비즈",
    pixelShapeBeads: "비즈",
    savechartasimage: "도안 이미지로 저장하기",
    saveQRasimage: "QR 이미지로 저장하기",
    isFifthRowShown: "5번째 줄 진하게",
    imageToPixel: "이미지 → 도안",
    createImageToPixel: "입력한 이미지를 도안으로 만들기",
    imageToPixelComment:
      "이미지 변환을 하기 전에 색상 개수와 행, 열 개수를 정해주세요!",
    undo: "실행 취소",
    redo: "다시 실행",
  },
  ja: {
    title: "編み物のためのカラーチャートを作成する",
    titleMenu: "タイトル",
    madeByMenu: "作成者",
    pixelShapeMenu: "ピクセルの形",
    pixelShapeSquare: "四角",
    pixelShapeKnitStitch: "ニット ステッチ",
    pixelShapeSingleCrochet: "細編み",
    pixelShapeHalfDoubleCrochet: "中長編み",
    pixelShapeDoubleCrochet: "長編み",
    pixelShapeRealKnitStitch: "本物 ニット ステッチ",
    pixelShapeRealSingleCrochet: "本物 細編み",
    pixelShapeRealHalfDoubleCrochet: "本物 中長編み",
    pixelShapeRealDoubleCrochet: "本物 長編み",
    addRow: "行を追加",
    deleteRow: "行を削除",
    addCol: "列を追加",
    deleteCol: "列を削除",
    colorPalletteMenu: "カラーパレット",
    create: "作成",
    share: "共有する",
    or: "または",
    shareTextQR: "このQRコードを友達と共有してください！",
    shareText: "ここをクリックしてこのURLをコピーし、友達と共有してください！",
    failToCreateQR: "QRコードの作成に失敗しました。リンクが長すぎます。",
    close: "閉じる",
    makeNewOne: "新しいものを作ってみてください！",
    pixelShapeGlossBeads: "ツヤビーズ",
    pixelShapeBeads: "ビーズ",
    savechartasimage: "この編み図を画像として保存",
    saveQRasimage: "このQRコードを画像として保存",
    isFifthRowShown: "5行目を太字にする",
    imageToPixel: "画像 → 図案",
    createImageToPixel: "入力した画像を編み図にする",
    imageToPixelComment:
      "画像を変換する前に、色の数と行、列の数を設定してください！",
    undo: "元に戻す",
    redo: "やり直す",
  },
};

export default messages;
