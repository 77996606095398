import React from "react";

const Vshape = (props) => {
  return (
    <div style={{ width: props.size, height: props.size }}>
      <svg viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3_3)">
          <rect width="400" height="400" fill="none" />

          <path
            d="M200 200C200 89.5431 289.543 0 400 0V200C400 310.457 310.457 400 200 400V200Z"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
          <path
            d="M0 0C110.457 0 200 89.5431 200 200V400C89.5431 400 0 310.457 0 200V0Z"
            fill={props.color}
            stroke="gray"
            stroke-width="20"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_3">
            <rect width="400" height="400" fill="none" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Vshape;
